import { useCylinder } from '@react-three/cannon'
import { useGLTF } from '@react-three/drei'
import { forwardRef } from 'react'

useGLTF.preload('/3dmodels/wheel.glb')

// Auto-generated by: https://github.com/pmndrs/gltfjsx
const BeetleWheel = forwardRef(
  ({ radius = 0.7, leftSide, ...props }: any, ref) => {
    const { nodes, materials }: any = useGLTF('/3dmodels/wheel.glb')
    useCylinder(
      () => ({
        mass: 1,
        type: 'Kinematic',
        material: 'wheel',
        collisionFilterGroup: 0,
        args: [radius, radius, 0.5, 16],
        ...props,
      }),
      // @ts-ignore
      ref,
    )
    return (
      // @ts-ignore
      <mesh ref={ref}>
        <mesh rotation={[0, 0, ((leftSide ? 1 : -1) * Math.PI) / 2]}>
          <mesh material={materials.Rubber} geometry={nodes.wheel_1.geometry} />
          <mesh material={materials.Steel} geometry={nodes.wheel_2.geometry} />
          <mesh material={materials.Chrom} geometry={nodes.wheel_3.geometry} />
        </mesh>
      </mesh>
    )
  },
)

export default BeetleWheel
